.btn.nav-link {
  background: transparent;
}

.navbar.navbar-custom {
  background-color: #7bba4c;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
  padding: 4px;
  z-index: 9999;
}

.navbar-nav-custom {
  padding-right: 20px;
}

.navbar-custom .navbar-nav-custom .nav-link {
  color: #fff;
}

.navbar-custom .navbar-nav-custom .nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}

.header-logo {
  position: absolute;
  height: 167px;
  width: 194px;
  top: 10px;
  left: 53px;
  background: url("logo_2.png") center;
  background-size: 100% 100%;
  z-index: 999999;
}

.current-town .nav-link {
  vertical-align: middle;
}

.current-town {
  padding-right: 15px;
}

.current-town .icon {
  padding-left: 5px;
}

@media only screen and (max-width: 991px) {
  .navbar-custom .navbar-nav-custom {
    flex-direction: row;
  }

  .navbar-custom .navbar-nav-custom .nav-item {
    display: flex;
  }

  .navbar-custom .navbar-nav-custom .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media only screen and (max-width: 700px) {
  .navbar-custom .navbar-nav-custom {
    padding-right: 0px;
  }

  .navbar-custom .header-logo {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-custom .navbar-nav-custom {
    margin-left: 0;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  .navbar-custom .navbar-nav-custom .current-town {
    flex: 1;
  }

  .navbar-custom .navbar-nav-custom .current-town .nav-link {
    display: block;
    width: 100%;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 450px) {
  .navbar-custom .navbar-nav-custom .current-town .nav-link .icon {
    display: none;
  }
}
