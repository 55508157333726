.map-wrapper {
  position: fixed;
  left: 300px;
  right: 0;
  top: 50px;
  bottom: 0;
}

.map {
  width: 100%;
  height: 100%;
}

.follow-the-kings-wrapper {
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px 10px 2px 2px;
  cursor: pointer;
}

.follow-the-kings-wrapper .label {
  margin-left: 5px;
  vertical-align: middle;
  font-size: 0.9rem;
}

.follow-the-kings-wrapper .checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(237, 114, 76, 1);
  display: inline-block;
  vertical-align: middle;
  padding: 2px;
}

.follow-the-kings-wrapper .checkbox .checkbox-inner {
  display: block;
  width: 100%;
  height: 100%;
}

.follow-the-kings-wrapper .checkbox.active .checkbox-inner {
  background-color: rgba(237, 114, 76, 1);
}

.ol-control button {
  background-color: rgba(237, 114, 76, 0.5);
}

.ol-control button:hover,
.ol-control button:focus {
  background-color: rgba(237, 114, 76, 1);
}

.map-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  overflow: scroll;
  text-align: center;
}

.map-modal:before {
  content: "";
  width: 1px;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.map-modal-content {
  display: inline-block;
  max-width: 400px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 20px;
  vertical-align: middle;
  text-align: left;
}

@media only screen and (max-width: 700px) {
  .map-wrapper {
    left: 0;
    top: 140px;
  }
}

@media only screen and (max-width: 750px) {
  .map-modal {
    padding: 10px;
  }

  .map-modal:before {
    display: none;
  }

  .map-modal-content {
    display: block;
  }
}
