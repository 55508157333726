.info-modal .modal-body {
  padding: 40px 40px 20px 40px;
}

.kings-img {
  max-width: 300px;
  margin: 0 auto;
}

.kings-msg {
  margin-top: 20px;
}

.credits {
  margin-top: 30px;
  font-size: 0.8rem;
}

.credits .heart {
  color: #ed724c;
}

.credits p {
  margin-bottom: 5px;
}

.close-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.close-wrapper button {
  color: #222;
}

.close-wrapper button:hover {
  color: #999;
}

@media only screen and (max-width: 575px) {
  .info-modal .modal-body {
    padding: 20px 20px;
  }
}
