.seacher-wrapper {
  position: absolute;
  right: 0;
  height: 38px;
  top: 0;
  width: 40px;
  justify-content: flex-end;
  background-color: #ed724c;
}

.seacher-wrapper.open {
  width: 100%;
}

.seacher-wrapper:not(.open) .form-control {
  display: none;
}

.seacher-wrapper .form-control {
  border: 0;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  font-weight: bold;
  border-radius: 0;
}

.seacher-wrapper .input-group-text {
  border: 0;
  background-color: transparent;
  color: #fff;
}
