.towns-list {
  list-style: none;
}

.towns-list .town-item {
  margin-bottom: 8px;
  border-bottom: 1px solid transparent;
}

.towns-list .town-item:hover {
  border-bottom: 1px solid #ec6d75;
}

.towns-list .town-item::before {
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  color: #ec6d75;
}

.towns-list .town-item .town-time {
  font-size: 0.8rem;
  color: #999999;
  float: right;
}

.towns-list .town-item.in-the-future::before {
  color: #aaaaaa;
}

.towns-list .town-item.in-the-future .town-name {
  color: #aaaaaa;
}

.towns-list .town-item.in-the-future .town-time {
  color: #aaaaaa;
}

.towns-list .town-item .here {
  display: inline-block;
  background-color: #ec6d75;
  padding: 4px 5px;
  line-height: 1em;
  border-radius: 8px;
  color: #fff;
  margin-left: 5px;
  font-size: 0.9rem;
}
