body {
  font-family: "Raleway", sans-serif;
}

.react-icons {
  vertical-align: middle;
}

.btn:focus,
button:focus,
.form-control:focus {
  outline: none !important;
  box-shadow: none;
}

.modal {
  z-index: 999999;
}

.modal-backdrop {
  z-index: 999990;
}

.modal-content {
  border: 0;
}

a:link {
  color: #7bba4c;
}

a:visited {
  color: #5c8b39;
}

a:hover {
  color: #b0d694;
}

a:active {
  color: #7bba4c;
}
