.sidebar {
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  width: 300px;
  background-color: #fbe3db;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.3);
  z-index: 99;
  padding-top: 200px;
}

.sidebar-logo {
  position: absolute;
  height: 200px;
  width: 300px;
  top: 0px;
  left: 0px;
  background: url("logo_1.png") center;
  background-size: 100% 100%;
  border-bottom: 1px solid #b25639;
}

.sidebar-section {
  height: 100%;
  padding-top: 40px;
  position: relative;
}

.sidebar-section .sidebar-section-header {
  background-color: #ed724c;
  color: #fff;
  border-bottom: 1px solid #b25639;
  padding: 8px 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.sidebar-section .sidebar-section-header h3 {
  font-size: 1.2rem;
  margin-bottom: 0;
  font-weight: bold;
  vertical-align: top;
}

.sidebar-section .sidebar-section-header h3 .icon {
  margin-right: 10px;
  vertical-align: top;
  display: none;
}

.sidebar-section-body {
  padding: 8px 15px;
  height: 100%;
  overflow: scroll;
}

@media only screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    padding-top: 50px;
    bottom: auto;
    height: 90px;
    overflow: hidden;
  }

  .sidebar-logo {
    width: 100%;
    background-size: 300px auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-color: #ed724c;
    height: 50px;
  }

  .sidebar.mobile-open {
    bottom: 0;
    height: auto;
  }

  .sidebar .searcher-icon {
    display: none;
  }

  .sidebar.mobile-open .searcher-icon {
    display: flex;
  }

  .sidebar-section .sidebar-section-header h3 .icon {
    display: inline-block;
  }
}
