.clock {
  height: 12vh;
  color: #222;
  font-size: 10vh;
  font-family: sans-serif;
  line-height: 12vh;
  display: flex;
  position: relative;
  background: white;
  overflow: hidden;
  justify-content: center;
}

.clock::before,
.clock::after {
  content: "";
  width: 7ch;
  height: 3vh;
  background: linear-gradient(to top, transparent, white);
  position: absolute;
  z-index: 2;
}

.clock::after {
  bottom: 0;
  background: linear-gradient(to bottom, transparent, white);
}

.clock > div {
  display: flex;
}

.tick {
  line-height: 11vh;
  color: #7bba4c;
}

.tick-hidden {
  opacity: 0;
}

.move {
  animation: move linear 1s infinite;
}

@keyframes move {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(-20vh);
  }
}
